import React from 'react';
import FullPage from '../../images/graphics/full.svg';
import HalfPage from '../../images/graphics/half.svg';
import QuarterPage from '../../images/graphics/quarter.svg';
import Icon from '../../images/graphics/icon.svg';
import Chart from '../../images/graphics/chart.svg';

const Book = () => (
	<article>
		<div id="book" className="abril">
			<h2 className="ptnum"><span className="part">Part</span> <span className="word">One</span> <span className="numeral">I</span></h2>

			<h2 className="pttitle">Part Title</h2>

			<img className="icon" src={Icon} alt="icon" />
			
			<h2 className="chnum"><span className="chapter">Chapter </span><span className="numeral">1</span><span className="word">One</span></h2>

			<h2 className="chtitle">The Chapter Title Goes Here</h2>

			<h2 className="chsub">Chapter Subtitle</h2>

			<h2 className="epi">And when we (to use Alice Walker&rsquo;s lovely phrase) go in search of our mothers&rsquo; gardens, it&rsquo;s not really to learn who trampled on them or how or even why&mdash;we usually know that already. Rather, it&rsquo;s to learn what our mothers planted there, what they thought as they sowed, and how they survived the blighting of so many fruits.</h2>

			<h2 className="episrc"><span>&mdash;Quote source,</span> <em>A Book</em></h2>

			<p><span className="intro">First paragraph of</span> the chapter goes here.</p>

			<p>All the other chapters go here.</p>

			<p>It is quiet with only baby babbles and the hum of the dishwasher, and I know I am lucky to live this life. I haven&rsquo;t always lived this way&mdash;I was a teacher, and my husband an entrepreneur&mdash;but I do realize how privileged we are now. I also know that when your insides are not well, it doesn&rsquo;t matter what your life looks like on the outside.</p>

			<p>And something inside me is not well. I do not feel happy like I believe I should be. I am regularly grateful, yes, but also darkly, penetratingly unhappy.</p>
			
			<p>Not many people know this. In fact, no one does. I have mastered the art of being polished, putting forth the right look and feel despite what bubbles beneath. It is not because I am fake, this practiced exterior. It is because I am strong. I take on everything no matter the consequences for myself. I am used to being the one whom other people depend on. Falling apart is not an option.</p>

			<p>So I keep quiet.</p>
			
			<p>But inside, my secret weighs a million tons. It leaks out the seams in arguments with my husband, aloofness with my children, and disassociation with myself. I don&rsquo;t know who I am anymore, and I can&rsquo;t seem to remember what lights me up. I am hungry to connect to a deeper purpose beyond daily duties and obligations. I don&rsquo;t know what I want and why I feel so low.</p>

			<p className="section">I arrive at the retreat center by myself. I am not a part of any program or group. I want to be alone. Talk to no one. Explain nothing. I want space, time to rest, time to reflect and read.</p>
			
			<p>And so I do. Day by day, under the Costa Rican sunshine, I grow stronger. The entire first two days I sleep. Through everything. Meals, classes, all of it. I show up to not one yoga or meditation class offered on-site. And as a person who always shows up for things, it feels pretty good to not show up for a damn thing.</p>
			
			<p>By my third day, I feel physically strong again. Now it is time to dig into my mental and emotional self. I read Gary Zukav, Caroline Myss, and Eckhart Tolle alone for hours in my bungalow. I journal on my porch or by the pool as I watch everyone else at the center scurry to classes and connect with each other. I dance naked in front of the mirror by myself to Beyoncé. I feel beautiful and at home in my own skin again.</p>

			<ul className="ul">
				<li>bulleted list first</li>
				<li>list</li>
				<li>bulleted</li>
				<li>list</li>
			</ul>

			<p>&ldquo;Preparation&rdquo; is seen as the &ldquo;input mode,&rdquo; like a computer&mdash;constantly receiving data or ideas. &ldquo;Incubation&rdquo; is the &ldquo;processing mode.&rdquo; This is a time when the input cooks. Here you forget about your inputs and just let them be&mdash;this is the phase of relaxation.</p>

			<p>&ldquo;Illumination&rdquo; is the &ldquo;output mode&rdquo; that comes as a result of incubation. Often, flashes of insight come during the incubation period, and illumination is where these insights are shared.</p>
			
			<p>Finally, there is &ldquo;verification.&rdquo; In verification, the illumination is compared to reason and possibly even received by a larger audience. It is here that creative insights are weighed for validity and inspiration.<span className="enref">3</span></p>

			<p>Of the four stages&mdash;preparation, incubation, illumination, and verification&mdash;can you guess which one is most frequently missing in our lives today? You guessed it: incubation.</p>

			<ol className="ol">
				<li>Numbered list first</li>
				<li>Numbered list</li>
				<li>Numbered list last</li>
			</ol>

			<p>Creative insights come from experiencing each of the four stages. What sets great minds apart from the common person is the ability to access this creativity with regularity and reach verification with creative outputs. This is impossible without making space for incubation.</p>

			<h2 className="h1">Heading 1</h2>

			<p>There is something enchanting about doing a retreat all by yourself&mdash;the hermit, the transcendentalist, the sage. Stepping away by yourself can be one of the fastest ways to your own inner knowing, like the masters of old times have always done.</p>

			<p>This may or may not be true for each of us individually. But solo retreats certainly offer a powerful opportunity for transformation, particularly if you find yourself regularly surrounded by people&mdash;maybe you&rsquo;re raising a family or constantly surrounded by coworkers or friends. A solo retreat is a great respite from lots of crowd stimulation. If you&rsquo;ve decided to go it alone, here are the factors to consider.</p>

			<h2 className="h2">Heading 2</h2>

			<p>The desire to know ourselves more deeply is what drives most of us to retreat. Or maybe you just plain need a break. But thinking more sincerely about your intention can help guide some of the important decisions you will need to make before you embark on your journey.</p>
			
			<p>Gaining clarity around your intention for retreat helps determine what kind of space you&rsquo;ll need as well as how to set your schedule. A person who wishes to strengthen her meditation practice will have a retreat that looks very different from someone who is looking to heal from a recent loss.</p>

			<p className="extract prose">
				<span className="h5">Extract Heading</span><br/>
				For this phase of <em>unease and apprehension</em>, it is important to create a new normal. The antidote for <em>unease and apprehension</em> is <em>norm setting and contextualization</em>.
			</p>

			<p>Some women mingled; some women stuck to themselves. Zadie wanted to socialize. This group was already bonding particularly strongly. Zadie regularly had women keeled over from laughter.</p>
			
			<img className="img" src={HalfPage} alt="graphic" />

			<p className="capt"><strong>Figure 5.1</strong> <em>The Land of Liberty: Recommended to the Consideration of Brother Jonathan</em>. Richard Doyle, <em>Punch Magazine</em>, December 13, 1847.</p>

			<p>A few women decided to grab a drink from the bar before the movie. And what the hell, they were having fun. One drink quickly became two.</p>

			<p>Zadie showed up to the movie feeling in good spirits. She was a regular pot smoker and came to the retreat wanting to look at this relationship. What moments did she reach for marijuana, and why? It was a question she continued to ask herself.</p>

			<p className="extract verse">
				Can a woman forget her nursing child,<br/>
				or show no compassion for the child of her womb?
			</p>

			<p className="extract verse">
				Even these may forget,<br/>
				yet I will not forget you.
			</p>

			<p className="extract verse">
				See, I have inscribed you on the palms of my hands.
			</p>

			<p>After moving through phase 1 of retreat, you will naturally enter phase 2: <em>excitement</em>. This is the beautiful high you&rsquo;ve been waiting for. It takes a lot to pull away. Finally arriving feels like a major accomplishment. And as you start to familiarize yourself with your new setting, there will be a natural build in emotion and energy.</p>

			<img className="chart" src={Chart} alt="chart" />

			<h2 className="h3">Heading 3 Open-Ended Beginning Questions</h2>

			<p>This schedule is designed for a participant on a solo retreat or at a retreat site where you run your own schedule.</p>

			<ul className="ul">
				<li>gentle morning movement (yoga, walking)</li>
				<li>meditation session #1</li>
				<li>journal writing: three pages</li>
				<li>breakfast</li>
				<li>hiking: engage the senses</li>
				<li>breath work to clear energy</li>
				<li>listen to teachings or read</li>
				<li>final journal entry</li>
				<li>personal care, shower, bathe, stretch</li>
			</ul>

			<h2 className="h4">Heading 4 Spiritual Practice</h2>

			<p>Below are some questions to ask yourself when thinking about your primary environments at home. Remember, if you do not create and manage your environment, it will manage you! Consider the following five elements when thinking about your home and your life.</p>

			<p>This may or may not be true for each of us individually. But solo retreats certainly offer a powerful opportunity for transformation, particularly if you find yourself regularly surrounded by people&mdash;maybe you&rsquo;re raising a family or constantly surrounded by coworkers or friends. A solo retreat is a great respite from lots of crowd stimulation. If you&rsquo;ve decided to go it alone, here are the factors to consider.</p>

			<ol className="ol">
				<li>What is the most important thing one needs to know about your faith community to understand it? Name a second thing. Perhaps a third.</li>
				<li>What makes your faith community vital or thriving?</li>
				<li>What biblical story or image best represents or describes this ministry/community for you?</li>
				<li>What is the generational and demographic character of this ministry/community?</li>
			</ol>

			<img className="img" src={FullPage} alt="graphic" />

			<p className="capt"><strong>Figure 5.7</strong> <em>Main Hall of the Library of Congress Room Inside the US Capitol</em>. Library of Congress.</p>

			<p>It is essential that class discussions be structured so that the dialectic of metalogue can be apprehended. I use the data gathered in assessment questionnaires and in the race, sex, class inventories to design my lectures and to  facilitate class discussions. The trick is to encourage a certain movement toward conscientization without squelching others. Like Ann Berthoff, I too have learned to come to class not thinking of a territory to be covered but with a compass to point the metalogical direction for a community of critically conscious ethicists.<span className="enref">12</span></p>

			<p>Womanist teaching is interactive. I read students&rsquo; papers the way I read letters: to learn. I conduct a running dialogue in the margins of each paper, suggesting supplemental readings, challenging students to find their ethical voice and to develop a method of moral reasoning that will stand them in good stead in any situation. The chief pedagogical value of weekly community announcements, lecture- note handouts (students have called them &ldquo;opaque transparencies&rdquo;), conscientization exercises, written course requirements, and an end-of-each-course annunciation/celebration ceremony is to encourage seminarians to see how they can embrace what Beverly Harrison calls a faith praxis that transforms life in the direction of nonalienating experience. The overall objective of this work implies moral notions about obligations, values, and virtues characterized by benevolent cohumanity and sacred power.<span className="enref">13</span></p>

			<div className="split">
				<img className="img quarter" src={QuarterPage} alt="graphic" />

				<p className="capt">Juan de Sáenz, <em>Virgen de Guadalupe con las cuatro apariciones</em> by Juan de Sáenz, c. 1777, Museo Soumaya, Mexico City, Mexico.</p>
			</div>

			<p className="fn"><span className="fnnum">1</span>&emsp;W. E. B. Du Bois, <em>The Souls of Black Folk</em> (New York: New American Library, 1969), xi.</p>

			<h2 className="chtitle chen">Chapter Endnotes</h2>

			<ol className="ol chen">
				<li>Sara Lawrence-Lightfoot, <em>I&rsquo;ve Known Rivers: Lives of Loss and Liberation</em> (New York: Penguin, 1995), 18.</li>
				<li>Lawrence-Lightfoot, 59&ndash;60.</li>
				<li>Cheryl J. Sanders, &ldquo;Womanist Ethics: Contemporary Trends and Themes,&rdquo; <em>Annual of the Society of Christian Ethics</em> 14 (1994): 299&ndash;305.</li>
				<li>Letty Russell, ed., <em>Feminist Interpretation of the Bible</em> (Philadelphia: Westminster, 1985), 40.</li>
				<li>Alice Walker, <em>In Search of Our Mothers&rsquo; Garden: Womanist Prose</em> (New York: Harcourt Brace Jovanovich, 1983), xi&ndash;xii.</li>
				<li>Hortense J. Spillers, &ldquo;Introduction: Who Cuts the Border? Some Readings on
				&lsquo;America,&rsquo;&rdquo; in <em>Comparative American Identities: Race, Sex and Nationality in the
				Modern Text</em>, ed. Hortense J. Spillers (New York: Routledge, 1991), 1&ndash;25.</li>
				<li>W. E. B. Du Bois, <em>The Souls of Black Folk</em> (New York: New American Library, 1969), xi.</li>
			</ol>

			<h2 className="chtitle bken">Book Endnotes</h2>

			<ol className="ol bken">
				<h2 className="h3 bken">Foreword</h2>
				<li>Sara Lawrence-Lightfoot, <em>I&rsquo;ve Known Rivers: Lives of Loss and Liberation</em> (New York: Penguin, 1995), 18.</li>
				<li>Lawrence-Lightfoot, 59&ndash;60.</li>

				<h2 className="h3 bken">Preface</h2>
				<li>Cheryl J. Sanders, &ldquo;Womanist Ethics: Contemporary Trends and Themes,&rdquo; <em>Annual of the Society of Christian Ethics</em> 14 (1994): 299&ndash;305.</li>
				<li>Letty Russell, ed., <em>Feminist Interpretation of the Bible</em> (Philadelphia: Westminster, 1985), 40.</li>

				<h2 className="h3 bken">Introduction</h2>
				<li>Alice Walker, <em>In Search of Our Mothers&rsquo; Garden: Womanist Prose</em> (New York: Harcourt Brace Jovanovich, 1983), xi&ndash;xii.</li>
				<li>Hortense J. Spillers, &ldquo;Introduction: Who Cuts the Border? Some Readings on
				&lsquo;America,&rsquo;&rdquo; in <em>Comparative American Identities: Race, Sex and Nationality in the
				Modern Text</em>, ed. Hortense J. Spillers (New York: Routledge, 1991), 1&ndash;25.</li>
				<li>W. E. B. Du Bois, <em>The Souls of Black Folk</em> (New York: New American Library, 1969), xi.</li>
			</ol>
		</div>
	</article>
);

export default Book;