export function buttonClick(event) {
	let button = event.target,
		form = button.closest('form'),
		formName = form.name,
		buttons = form.getElementsByTagName('button');

	if (formName === 'layouts') {
		var option = button.name,
			book = document.getElementById('book');

		book.className = option;
	}

	Array.prototype.forEach.call(buttons, item => {
		item.classList.remove('active');
	});

	button.classList.toggle('active');
}

export function checkboxClick(event) {
	let element = event.target.value,
		isChecked = event.target.checked,
		book = document.getElementById('book'),
		elements = book.querySelectorAll('.' + element);

	if (isChecked) {
		Array.prototype.forEach.call(elements, item => {
			item.classList.remove('hidden');
		});
	} else {
		Array.prototype.forEach.call(elements, item => {
			item.classList.add('hidden');
		});
	}
}

export function resetElements(event) {
	event.preventDefault();

	let elementsForm = document.getElementById('elements'),
		checkboxes = elementsForm.querySelectorAll('input[type="checkbox"]'),
		book = document.getElementById('book'),
		hiddenElements = book.querySelectorAll('.hidden');

	if (window.confirm("Are you sure you want to reset the book elements?")) {
		Array.prototype.forEach.call(checkboxes, item => {
			item.checked = true;
		});

		Array.prototype.forEach.call(hiddenElements, item => {
			item.classList.remove('hidden');
		});

		window.scrollTo(0,0);
	}
}