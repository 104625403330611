const CASTOFF = {
	'abril': {
		'5x7': 236,
		'5.5x7.5': 280,
		'5.5x8.5': 285,
		'6x9': 333,
		'7x9': 381
	},
	'adorn': {
		'5x7': 176,
		'5.5x7.5': 223,
		'5.5x8.5': 258,
		'6x9': 289,
		'7x9': 350
	},
	'agenda': {
		'5x7': 177,
		'5.5x7.5': 211,
		'5.5x8.5': 222,
		'6x9': 258,
		'7x9': 300
	},
	'brandon': {
		'5x7': 191,
		'5.5x7.5': 234,
		'5.5x8.5': 282,
		'6x9': 325,
		'7x9': 372
	},
	'garamond': {
		'5x7': 216,
		'5.5x7.5': 263,
		'5.5x8.5': 288,
		'6x9': 333,
		'7x9': 365
	},
	'garamond_light': {
		'5x7': 160,
		'5.5x7.5': 178,
		'5.5x8.5': 203,
		'6x9': 239,
		'7x9': 285
	},
	'gothic': {
		'5x7': 207,
		'5.5x7.5': 212,
		'5.5x8.5': 214,
		'6x9': 266,
		'7x9': 285
	},
	'ivy': {
		'5x7': 195,
		'5.5x7.5': 242,
		'5.5x8.5': 279,
		'6x9': 340,
		'7x9': 392
	},
	'museo': {
		'5x7': 181,
		'5.5x7.5': 219,
		'5.5x8.5': 234,
		'6x9': 267,
		'7x9': 305
	},
	'myriad': {
		'5x7': 220,
		'5.5x7.5': 252,
		'5.5x8.5': 293,
		'6x9': 334,
		'7x9': 395
	},
	'myriad_light': {
		'5x7': 162,
		'5.5x7.5': 175,
		'5.5x8.5': 210,
		'6x9': 249,
		'7x9': 266
	},
	'fortress': {
		'5x7': 227,
		'5.5x7.5': 264,
		'5.5x8.5': 301,
		'6x9': 321,
		'7x9': 385
	},
	'mighty_fortress': {
		'5x7': 270,
		'5.5x7.5': 320,
		'5.5x8.5': 367,
		'6x9': 459,
		'7x9': 498
	}
}

export default CASTOFF;