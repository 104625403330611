export const DOWNLOADS = [
	"abril-5.5x7.5",
	"abril-5.5x8.5",
	"abril-5x7",
	"abril-6x9",
	"abril-7x9",
	"adorn-5.5x7.5",
	"adorn-5.5x8.5",
	"adorn-5x7",
	"adorn-6x9",
	"adorn-7x9",
	"agenda-5.5x7.5",
	"agenda-5.5x8.5",
	"agenda-5x7",
	"agenda-6x9",
	"agenda-7x9",
	"brandon-5.5x7.5",
	"brandon-5.5x8.5",
	"brandon-5x7",
	"brandon-6x9",
	"brandon-7x9",
	"fortress-5.5x8.5",
	"fortress-5.5x7.5",
	"fortress-5x7",
	"fortress-6x9",
	"fortress-7x10",
	"garamond-5.5x7.5",
	"garamond-5.5x8.5",
	"garamond-5x7",
	"garamond-6x9",
	"garamond-7x9",
	"garamond_light-5.5x7.5",
	"garamond_light-5.5x8.5",
	"garamond_light-5x7",
	"garamond_light-6x9",
	"garamond_light-7x9",
	"gothic-5.5x7.5",
	"gothic-5.5x8.5",
	"gothic-5x7",
	"gothic-6x9",
	"gothic-7x9",
	"ivy-5.5x7.5",
	"ivy-5.5x8.5",
	"ivy-5x7",
	"ivy-6x9",
	"ivy-7x9",
	"mighty_fortress-5.5x7.5",
	"mighty_fortress-5.5x8.5",
	"mighty_fortress-5x7",
	"mighty_fortress-6x9",
	"mighty_fortress-7x9",
	"museo-5.5x7.5",
	"museo-5.5x8.5",
	"museo-5x7",
	"museo-6x9",
	"museo-7x9",
	"myriad-5.5x7.5",
	"myriad-5.5x8.5",
	"myriad-5x7",
	"myriad-6x9",
	"myriad-7x9",
	"myriad_light-5.5x7.5",
	"myriad_light-5.5x8.5",
	"myriad_light-5x7",
	"myriad_light-6x9",
	"myriad_light-7x9"
];