import React from 'react';

import Sidebar from '../Sidebar';
import Book from '../Book';

const HomePage = () => (
	<main>
		<Sidebar />
		<Book />
	</main>
);

export default HomePage;