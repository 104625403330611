import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { buttonClick, checkboxClick, resetElements } from '../../utils.js';
import Download from '../../images/download.svg';
import Open from '../../images/open.svg';

import Castoff from '../Castoff';
import { DOWNLOADS } from '../../constants/downloads';
import * as ROUTES from '../../constants/routes';

class Sidebar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			template: "abril",
			dimensions: "5x7",
			available: true,
			castoffActive: false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.template !== prevState.template || this.state.dimensions !== prevState.dimensions) {
			this.setState({
				available: this.checkDownloadSrc(this.state.template, this.state.dimensions)
			});
		}
	}

	onButtonClick = (event) => {
		event.preventDefault();
		this.setState({ template: event.target.value });
		buttonClick(event);
	}

	onDimensionsClick = (event) => {
		event.preventDefault();
		this.setState({ dimensions: event.target.value });
		buttonClick(event);
	}

	onCastoffClick = (event) => {
		event.preventDefault();
		this.setState({ castoffActive: !this.state.castoffActive });
	}

	onCastoffClose = () => {
		this.setState({ castoffActive: false });
	}

	checkDownloadSrc = (template, dimensions) => {
		if (DOWNLOADS.indexOf(`${template}-${dimensions}`) > -1) {
			return true;
		} else {
			return false;
		}
	}

	render() {
		let downloadSrc = `/downloads/${this.state.template}-${this.state.dimensions}.pdf`;

		return (
			<>
				<aside>
					<header>
						<div className="logo">
							<Link to={ROUTES.HOME}>Home</Link>
						</div>
					</header>

					<div>
						<form name="layouts" id="layouts">
							<h5>Layout Options</h5>

							<div className="two-col">
								<button name="abril" value="abril" className="active" onClick={this.onButtonClick} style={{ fontFamily: 'abril-fatface, serif' }}>
									Abril
								</button>

								{/* <button name="adorn" value="adorn" onClick={this.onButtonClick} style={{ fontFamily: 'adorn-roman, sans-serif', fontSize: '0.75rem' }}>
									Adorn
								</button> */}

								<button name="agenda" value="agenda" onClick={this.onButtonClick} style={{ fontFamily: 'agenda, sans-serif', fontWeight: '600' }}>
									Agenda
								</button>

								<button name="brandon" value="brandon" onClick={this.onButtonClick} style={{ fontFamily: 'brandon-grotesque, sans-serif', fontWeight: 'bold', fontSize: '0.75rem' }}>
									Brandon
								</button>

								<button name="gothic" value="gothic" onClick={this.onButtonClick} style={{ fontFamily: 'alternate-gothic-atf, sans-serif' }}>
									Gothic
								</button>

								<button name="ivy" value="ivy" onClick={this.onButtonClick} style={{ fontFamily: 'ivypresto-display, serif' }}>
									Ivy
								</button>

								<button name="museo" value="museo" onClick={this.onButtonClick} style={{ fontFamily: 'museo-slab, serif' }}>
									Museo
								</button>

								<div className="two-col temp-group">
									<button name="fortress" value="fortress" onClick={this.onButtonClick} style={{ fontFamily: 'garamond-premier-pro, serif', letterSpacing: '1px' }}>
										Fortress
									</button>

									<button name="mighty_fortress" value="mighty_fortress" onClick={this.onButtonClick} style={{ fontFamily: 'garamond-premier-pro, serif', letterSpacing: '1px' }}>
										Mighty Fortress
									</button>
								</div>

								<div className="two-col temp-group">
									<button name="garamond" value="garamond" onClick={this.onButtonClick} style={{ fontFamily: 'garamond-premier-pro, serif', letterSpacing: '1px' }}>
										Garamond
									</button>

									<button name="garamond_light" value="garamond_light" onClick={this.onButtonClick} style={{ fontFamily: 'garamond-premier-pro, serif', letterSpacing: '1px' }}>
										Garamond Light
									</button>
								</div>

								<div className="two-col temp-group">
									<button name="myriad" value="myriad" onClick={this.onButtonClick} style={{ fontFamily: 'myriad-pro-condensed, sans-serif', fontSize: '0.9rem' }}>
										Myriad
									</button>

									<button name="myriad_light" value="myriad_light" onClick={this.onButtonClick} style={{ fontFamily: 'myriad-pro-condensed, sans-serif', fontSize: '0.9rem' }}>
										Myriad Light
									</button>
								</div>
							</div>
						</form>
					</div>

					<div className="elements">
						<form name="elements" id="elements">
							<h5>Elements</h5>

							<div className="labels">
								<label>
									<input type="checkbox" value="ptnum" defaultChecked onClick={checkboxClick} />
									Part Number
								</label>

								<label>
									<input type="checkbox" value="pttitle" defaultChecked onClick={checkboxClick} />
									Part Title
								</label>

								<label>
									<input type="checkbox" value="icon" defaultChecked onClick={checkboxClick} />
									Chapter Icon
								</label>

								<label>
									<input type="checkbox" value="chnum" defaultChecked onClick={checkboxClick} />
									Chapter Number
								</label>

								<label>
									<input type="checkbox" value="chtitle" defaultChecked onClick={checkboxClick} />
									Chapter Title
								</label>

								<label>
									<input type="checkbox" value="chsub" defaultChecked onClick={checkboxClick} />
									Chapter Subtitle
								</label>

								<label>
									<input type="checkbox" value="epi" defaultChecked onClick={checkboxClick} />
									Epigraph
								</label>

								<label>
									<input type="checkbox" value="episrc" defaultChecked onClick={checkboxClick} />
									Epigraph Source
								</label>

								<label>
									<input type="checkbox" value="h1" defaultChecked onClick={checkboxClick} />
									Heading 1
								</label>

								<label>
									<input type="checkbox" value="h2" defaultChecked onClick={checkboxClick} />
									Heading 2
								</label>

								<label>
									<input type="checkbox" value="h3" defaultChecked onClick={checkboxClick} />
									Heading 3
								</label>

								<label>
									<input type="checkbox" value="h4" defaultChecked onClick={checkboxClick} />
									Heading 4
								</label>

								<label>
									<input type="checkbox" value="prose" defaultChecked onClick={checkboxClick} />
									Extract (Prose)
								</label>

								<label>
									<input type="checkbox" value="verse" defaultChecked onClick={checkboxClick} />
									Extract (Verse)
								</label>

								<label>
									<input type="checkbox" value="ul" defaultChecked onClick={checkboxClick} />
									Bulleted List
								</label>
								
								<label>
									<input type="checkbox" value="ol" defaultChecked onClick={checkboxClick} />
									Numbered List
								</label>

								<label>
									<input type="checkbox" value="img" defaultChecked onClick={checkboxClick} />
									Image/Graphic
								</label>

								<label>
									<input type="checkbox" value="chart" defaultChecked onClick={checkboxClick} />
									Table/Chart
								</label>

								<label>
									<input type="checkbox" value="capt" defaultChecked onClick={checkboxClick} />
									Caption
								</label>

								<label>
									<input type="checkbox" value="fn" defaultChecked onClick={checkboxClick} />
									Footnotes
								</label>

								<label>
									<input type="checkbox" value="chen" defaultChecked onClick={checkboxClick} />
									Chapter Endnotes
								</label>

								<label>
									<input type="checkbox" value="bken" defaultChecked onClick={checkboxClick} />
									Book Endnotes
								</label>
							</div>
							
							<button className="reset" onClick={resetElements}>Reset</button>
						</form>
					</div>

					<div className="download">
						<form name="download">
							<h5>Dimensions</h5>

							<div>
								<div className="dimensions">
									<button className="active" name="5x7" value="5x7" onClick={this.onDimensionsClick}>
										5" x 7"
									</button>

									<button name="5.5x7.5" value="5.5x7.5" onClick={this.onDimensionsClick}>
										5.5" x 7.5"
									</button>

									<button name="5.5x8.5" value="5.5x8.5" onClick={this.onDimensionsClick}>
										5.5" x 8.5"
									</button>

									<button name="6x9" value="6x9" onClick={this.onDimensionsClick}>
										6" x 9"
									</button>

									<button name="7x9" value="7x9" onClick={this.onDimensionsClick}>
										7" x 9"
									</button>
								</div>

								<div>
									<button className="download" name="castoff" value={this.state} onClick={this.onCastoffClick} style={{ lineHeight: '36px' }}>
										<img src={Open} alt="open window icon" />&ensp;Castoff
									</button>

									<a className={ this.state.available ? "download" : "download disabled" } name="download" href={downloadSrc} download>
										<img src={Download} alt="download icon" />&ensp;Sample
									</a>

									<a className="download" href="/downloads/BookTemplates-SpecSheet.pdf" download>
										<img src={Download} alt="download icon" />&ensp;Spec Sheet
									</a>
								</div>
							</div>
						</form>
					</div>
				</aside>

				<Castoff castoffActive={this.state.castoffActive} onCastoffClose={this.onCastoffClose.bind(this)} template={this.state.template} dimensions={this.state.dimensions} />
			</>
		);
	}
}

export default Sidebar;