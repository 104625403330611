import React, { useEffect, useState } from 'react';
import CASTOFF from '../../constants/castoff';

const Castoff = (props) => {
	const template = props.template.split('_').join(' ');
	
	const wordsPerPage = CASTOFF[props.template][props.dimensions];
	const [wordsNum, setWordsNum] = useState(0);
	const [wordsPageCount, setWordsPageCount] = useState(Math.ceil(wordsNum / wordsPerPage));

	const charPerPage = wordsPerPage * 6;
	const [charNum, setCharNum] = useState(0);
	const [charPageCount, setCharPageCount] = useState(Math.ceil(charNum / charPerPage));

	useEffect(() => {
		setWordsPageCount(Math.ceil(wordsNum / wordsPerPage));
		setCharPageCount(Math.ceil(charNum / charPerPage));
	}, [wordsNum, wordsPerPage, charNum, charPerPage]);

	return (
		<div className={ props.castoffActive ? 'castoff active' : 'castoff' }>
			<span className="close" onClick={props.onCastoffClose} />

			<h3>{template} <span>{props.dimensions}</span></h3>

			<div>
				<p>Words per page: <span>{wordsPerPage === 0 ? "N/A" : wordsPerPage}</span></p>
				<label htmlFor="wordsNum">Number of words</label>
				<input 
					name="wordsNum"
					type="number"
					placeholder="0"
					onChange={(event) => setWordsNum(event.target.value)}
					disabled={isNaN(wordsPageCount) ? true : false }
				/>
				<p className="page-count">Page count: <span>{isNaN(wordsPageCount) ? "N/A" : wordsPageCount}</span></p>
			</div>

			<div>
				<p>Characters per page: <span>{charPerPage === 0 ? "N/A" : charPerPage}</span></p>
				<label htmlFor="charNum">Number of characters</label>
				<input
					name="charNum"
					type="number"
					placeholder="0"
					onChange={(event) => setCharNum(event.target.value)}
					disabled={isNaN(charPageCount) ? true : false }
				/>
				<p className="page-count">Page count: <span>{isNaN(charPageCount) ? "N/A" : charPageCount}</span></p>
			</div>
		</div>
	);
}

export default Castoff;